<template>
  <div class="footer-list">
    <div v-for="(item, index) in list" :key="item.title" class="list-item">
      <div class="surface" @click="toggleSection(index)">
        <h2 class="category-name">{{ item.title }}</h2>
        <div class="cross" :class="{ open: openSections[index] }">
          <div class="cross-vertical" />
          <div class="cross-horizontal" />
        </div>
      </div>
      <div ref="linkSections" class="links">
        <div class="links-wrapper">
          <template v-for="link in item.links" :key="link.name">
            <NuxtLink class="link" :to="link.url">{{ link.name }}</NuxtLink>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { gsap } from "gsap";

const props = defineProps({
  list: {
    type: Array,
    required: true,
  },
});

const openSections = ref(props.list.map(() => false));
const linkSections = ref([]);

onMounted(() => {
  // Initialize all sections to height 0
  linkSections.value.forEach((section) => {
    gsap.set(section, { height: 0 });
  });
});

const toggleSection = (index) => {
  openSections.value[index] = !openSections.value[index];

  if (openSections.value[index]) {
    gsap.to(linkSections.value[index], {
      height: "auto",
      duration: 0.4,
      ease: "power1.inOut",
    });
  } else {
    gsap.to(linkSections.value[index], {
      height: 0,
      duration: 0.4,
      ease: "power1.inOut",
    });
  }
};
</script>

<style lang="scss" scoped>
.footer-list {
  position: relative;
  padding-bottom: $padding-mobile-big;
}

.list-item {
  padding-top: 1.3rem;
  border-top: solid 0.42px $black;
  overflow: hidden;
}

.surface {
  position: relative;
}

.category-name {
  @include menu-category;
  line-height: 3rem;
}

.links {
  margin-bottom: 0.5rem;
}

.links-wrapper {
  overflow: hidden;
  @include grid-mobile($margin: 0);
  padding: 1.5rem 0 3.5rem;
}

.link {
  @include mobile-path;
  color: rgba($color: #000000, $alpha: 0.68);
  grid-column: span 6;
  overflow: hidden;
  white-space: nowrap;
}

.cross {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0.1rem;
  top: 0.5rem;
}

.cross-vertical,
.cross-horizontal {
  background-color: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cross-vertical {
  height: 15px;
  width: 1px;
}

.cross-horizontal {
  height: 1px;
  width: 15px;
}

.cross.open {
  .cross-vertical {
    opacity: 0;
  }
}
</style>
