<template>
  <div class="footer-signup">
    <label :for="inputId" class="signup-label">{{ props.label }}</label>
    <div class="input-wrapper">
      <input
        :id="inputId"
        v-model="email"
        type="email"
        class="email-input"
        placeholder="Email address"
        required
      />
      <button
        type="button"
        class="submit-button"
        aria-label="Submit email"
        @click="submitEmail"
      >
        <EmailArrow class="submit-icon" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import EmailArrow from "@/assets/images/icons/email-arrow.svg";

const props = defineProps({
  label: {
    type: String,
  },
  id: {
    type: String,
    default: "footer-signup",
  },
});

const email = ref("");
const inputId = ref(props.id);

onMounted(() => {
  inputId.value = `${props.id}-${Math.random().toString(36).substr(2, 9)}`;
});

const submitEmail = () => {
  // Logique de soumission ici
  // Réinitialiser l'email après soumission
  email.value = "";
};
</script>

<style
  src="@/assets/css/components/footer/footer-signup.scss"
  lang="scss"
  scoped
></style>
