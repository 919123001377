<template>
  <div class="footer">
    <FooterSignUp :label="footer.data.sign_up_label" />
    <FooterLinksList :list="groupedLinks" />
    <div class="footer-bottom">
      <div class="row">
        <a target="_blank" href="https://rejouice.com/">
          <span class="made-by">
            Made by
            <Rejouice />
          </span>
        </a>
        <LogoBlack class="footer-logo" />
        <p class="footer-space">The Tailored Space</p>
      </div>
      <DuchateauBlack class="duchateau-black" />
    </div>
  </div>
</template>

<script setup>
import Rejouice from "@/assets/images/icons/rejouice.svg";
import LogoBlack from "@/assets/images/icons/duchateau-logo-black.svg";
import DuchateauBlack from "@/assets/images/duchateau-black.svg";
const { client } = usePrismic();
const { data: footer } = await useAsyncData("footer", () =>
  client.getSingle("footer")
);

const groupedLinks = computed(() => {
  if (!footer.value || !footer.value.data) return [];

  return [
    { title: "Customer", links: footer.value.data.customer_links },
    { title: "Social", links: footer.value.data.social_links },
    { title: "Legal", links: footer.value.data.legal_links },
    { title: "Company", links: footer.value.data.company_links },
  ].filter((group) => group.links && group.links.length > 0);
});
</script>

<style lang="scss" scoped>
.footer {
  padding: 2.5rem var(--padding-mobile) 0;
  background-color: $beige;
}

.footer-bottom {
  position: relative;
}

.row {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 0.42px $black;
  padding-bottom: 1rem;
}

.made-by {
  display: flex;
  align-items: center;
  @include caption-small;
  svg {
    margin-left: 0.3rem;
    transform: scale(1.1) translate(2px, 0.5px);
  }
}

.footer-logo {
  position: absolute;
  left: 50%;
  top: -0.5rem;
  transform: translateX(-50%);
}

.footer-space {
  @include caption-small;
}

.duchateau-black {
  width: 100%;
  margin-top: 3rem;
  padding-bottom: 2rem;
}
</style>
