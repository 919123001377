<template>
  <FooterDesktop
    class="footer-desktop"
    :image="props.image"
    :page="props.page"
    :text="props.text"
    :next="props.next"
  />
  <FooterMobile class="footer-mobile" />
</template>

<script setup>
const props = defineProps({
  image: Object,
  page: String,
  text: String,
  next: {
    type: Boolean,
    default: true,
  },
});
</script>

<style lang="scss" scoped>
.footer-desktop {
  display: none;
  @include desktop {
    display: block;
  }
}

.footer-mobile {
  display: block;
  @include desktop {
    display: none;
  }
}
</style>
