<template>
  <div ref="footerContainer" class="footer-container">
    <div ref="footerEl" class="footer">
      <div class="footer-top">
        <div class="footer-links">
          <div
            v-for="category in groupedLinks"
            :key="category.title"
            class="footer-category"
          >
            <h2 class="category-name">{{ category.title }}</h2>
            <template v-for="(link, i) in category.links" :key="i">
              <NuxtLink class="category-link" :to="link.url">
                <span class="first">{{ link.name }}</span>
              </NuxtLink>
            </template>
          </div>
        </div>
        <LogoBlack class="logo-black" />
        <FooterSignUp class="sign-up" :label="footer.data.sign_up_label" />
      </div>
      <div class="footer-space-container">
        <p class="footer-space">The Tailored Space</p>
        <div class="additional-container">
          <a target="_blank" href="https://rejouice.com/">
            <div class="made-by">
              Made by
              <div class="rejouice-svg">
                <Rejouice />
              </div>
            </div>
          </a>
          <div class="toTop-container" @click="goToTop">
            <ArrowTop class="toTop" />
          </div>
        </div>
      </div>
      <div class="footer-separator" />
      <DuchateauBlack ref="logo" />
    </div>
    <template v-if="props.next !== false">
      <div ref="revealEl" class="footer-reveal">
        <div ref="overlay" class="overlay" />
        <CustomImage :image="props.image" :sizes="[1440, 1440]" />

        <Link :label="props.text" :link="props.page" :light="true" />
      </div>
    </template>
  </div>
</template>

<script setup>
import LogoBlack from "@/assets/images/icons/duchateau-logo-black.svg";
import DuchateauBlack from "@/assets/images/duchateau-black.svg?skipsvgo";
import ArrowTop from "@/assets/images/icons/arrow-top.svg";
import Link from "@/components/Link.vue";
import Rejouice from "@/assets/images/icons/rejouice.svg";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const { $lenis } = useNuxtApp();

const { client } = usePrismic();
const { data: footer } = await useAsyncData("footer", () =>
  client.getSingle("footer")
);

let ctx;

const props = defineProps({
  image: Object,
  page: String,
  text: String,
  next: Boolean,
});

const footerContainer = shallowRef(null);
const footerEl = shallowRef(null);
const revealEl = shallowRef(null);
const logo = shallowRef(null);
const revealHeight = ref(0);
const overlay = shallowRef(null);

onMounted(() => {
  nextTick(() => {
    if (props.next) {
      gsap.delayedCall(1, () => {
        onResize();
        initListeners();
        initFooterReveal();
      });
    }
  });
});

let revealTL;

const initFooterReveal = () => {
  ctx = gsap.context(() => {
    revealTL?.kill();
    revealTL = gsap.timeline({
      defaults: {
        ease: "none",
      },
    });

    revealTL.fromTo(
      revealEl.value,
      {
        y: () => -revealHeight.value / 2 + "px",
      },
      {
        y: 0,
      },
      0
    );

    revealTL.fromTo(
      overlay.value,
      {
        opacity: 0.7,
      },
      {
        opacity: 0,
      },
      0
    );

    ScrollTrigger.create({
      animation: revealTL,
      trigger: footerEl.value,

      start: "bottom bottom",
      end: () => `+=${revealHeight.value}px`,
      scrub: true,
    });
  });
};

const onResize = () => {
  if (revealEl.value) {
    revealHeight.value = revealEl.value.offsetHeight;
    initFooterReveal();
  }
};

const initListeners = () => {
  window.addEventListener("resize", onResize);
};

const groupedLinks = computed(() => {
  if (!footer.value || !footer.value.data) return [];

  return [
    { title: "Customer", links: footer.value.data.customer_links },
    { title: "Social", links: footer.value.data.social_links },
    { title: "Company", links: footer.value.data.company_links },
    { title: "Legal", links: footer.value.data.legal_links },
  ].filter((group) => group.links && group.links.length > 0);
});

const goToTop = () => {
  $lenis.scrollTo(0);
};

onBeforeUnmount(() => {
  ctx?.kill(false);
});
</script>

<style lang="scss" scoped>
.footer-container {
  position: relative;
  overflow: hidden;
}

.footer {
  background-color: $beige;
  padding: 2rem $padding-desktop 3.5rem;
  position: relative;
  z-index: 2;
}

.footer-top {
  @include grid-desktop();
  justify-content: space-between;
  margin-bottom: 14.7rem;
}

.sign-up {
  grid-column: 10 / span 3;
  padding-bottom: 0;
}

.footer-links {
  grid-column: 1 / span 4;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 2.4rem;
}

.footer-category {
  grid-column: span 1;
  width: calc(100% + 2rem);

  display: flex;
  flex-direction: column;
}

.category-name {
  @include caption-small;
  margin-bottom: 1.1rem;
}

.category-link {
  @include link;

  font-size: 11px !important;
  line-height: 1.1 !important;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  @include desktop {
    width: fit-content;
    max-width: 85%;
    margin-bottom: 0.5rem;
    &:hover {
      text-decoration: underline;
    }
  }

  // span {
  //   will-change: transform;
  //   transition: 0.3s $ease-in-out-quad;
  // }

  // &:hover {
  //   .first {
  //     transform: translateY(-100%);
  //   }
  //   .second {
  //     transform: translateY(0%);
  //   }
  // }

  // .second {
  //   position: absolute;
  //   transform: translateY(100%);
  // }
}

.logo-black {
  position: absolute;
  top: 4.2rem;
  left: 50%;
  transform: translateX(-50%);
}

.footer-space-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-space {
  @include caption-small;
}

.footer-separator {
  width: 100%;
  height: 1px;
  background-color: $black;
  margin-bottom: 2.5rem;
}

.toTop-container {
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.footer-reveal {
  height: 50rem;
  width: 100%;
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  :deep(img) {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    inset: 0;
  }
}

.overlay {
  background-color: black;
  opacity: 0.7;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}

.additional-container {
  display: flex;
  align-items: center;
}

.made-by {
  display: flex;
  align-items: baseline;

  font-family: $beaufort-regular;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;

  @include tablet {
    font-size: 20px;
    line-height: 21px;
  }

  @include desktop {
    font-size: 16px;
    line-height: 17px;
  }
}

.rejouice-svg {
  @include link-active;
  position: relative;
  margin-left: 0.3rem;
  display: inline-block;
  overflow: visible;
  height: auto;
  &::after {
    bottom: -0.35rem;
  }
}
</style>
